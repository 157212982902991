import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { AUTH_USERS } from "../constants";

export const signIn = async (passcode) => {
  const auth = getAuth();
  let authUser = AUTH_USERS[0];
  if (passcode[4] === "i") authUser = AUTH_USERS[1];
  try {
    await signInWithEmailAndPassword(auth, authUser.email, passcode);
    return authUser;
  } catch (error) {
    console.log(error);
  }
  throw Error("Sign in failed.");
}