import { useContext } from "react";

import { PersonContext } from "../../pages/dashboard";

export default function WhoOwesYouList({ owedBy }) {
  const { povPersonId, PEOPLE } = useContext(PersonContext);

  return (
    <>
    {
      Object.keys(owedBy).map(from => {
        let fromPerson = PEOPLE.filter(p => (p.id === from))[0];
        let name = fromPerson.name[povPersonId] ?? fromPerson.name._;
        return <p key={from}>
          {name} owes you ${owedBy[from].toFixed(2)}<br />
        </p>
      })
    }
    </>
  );
}