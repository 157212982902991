import { Form } from "react-bootstrap";

import { ALLEACH } from "../../../../constants";

export default function AllEachSelect({ allEach, setAllEach }) {
  return (
    <Form.Select 
      aria-label="AllEach select"
      onChange={e => setAllEach(e.target.value)}
      value={allEach}
    >
      <option value={ALLEACH.all}>altogether</option>
      <option value={ALLEACH.each}>each</option>
    </Form.Select>
  )
}