import { useMemo } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components'
import { Container } from '@mui/material';

const Styles = styled.div`
  padding: 1rem;

  table {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
    border: 1px solid #dadada;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #dadada;
      border-right: 1px solid #dadada;

      :last-child {
        border-right: 0;
      }
    }
  }
  `
  
export function Table({ data }) {
  const columns = useMemo(() => [
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
    },
    {
      Header: 'From',
      accessor: 'from',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'To',
      accessor: 'to',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Description',
      accessor: 'description'
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data
  })

  return (
    <Styles>
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  </Styles>
  )
}