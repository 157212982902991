import { Form } from "react-bootstrap";
import { useContext } from "react";

import { PersonContext } from "../../../../pages/dashboard";

export default function RecipientSelect({ 
  onRecipientChange, oweIds, recipientId }) {
  const { povPersonId, PEOPLE } = useContext(PersonContext);

  return (
    <Form.Select 
      aria-label="Person select" 
      onChange={e => onRecipientChange(e.target.value)}
      value={recipientId}
      required
    >
      <option value="">Select</option>
      {
        PEOPLE.filter(person => (oweIds.includes(person.id)))
          .map(person => {
            let name = person.name[povPersonId] ?? person.name._;
            return <option 
              key={person.id}
              value={person.id}
              >
              {name}
            </option>
        })}
    </Form.Select>
  );
}