import * as React from 'react';
import { useContext } from 'react';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import { PersonContext } from '../../pages/dashboard';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, selectedIds, theme) {
  return {
    fontWeight:
      selectedIds.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PersonMultiSelect({ selectedIds, setSelectedIds }) {
  const theme = useTheme();
  const { povPersonId, PEOPLE } = useContext(PersonContext);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Select
      labelId="multiple-person-select-label"
      id="multiple-person-select-name"
      multiple
      value={selectedIds}
      onChange={handleChange}
      input={<OutlinedInput label="Name" notched={false}/>}
      MenuProps={MenuProps}
      style={{ width: '250px', height: '40px' }}
      required
    >
      {PEOPLE.map((person) => {
        // let name = person.name[povPersonId] ?? person.name._;
        return <MenuItem
          key={person.id}
          value={person.id}
          style={getStyles(person.id, selectedIds, theme)}
        >
          {person.name[povPersonId] ?? person.name._}
        </MenuItem>
      })}
    </Select>
  );
}