import { useContext } from "react";

import { PersonContext } from "../../pages/dashboard";

export default function YouOweList({ owe }) {
  const { povPersonId, PEOPLE } = useContext(PersonContext);
  return (
    <>
    {
      Object.keys(owe).map(to => {
        let toPerson = PEOPLE.filter(p => (p.id === to))[0];
        let name = toPerson.name[povPersonId] ?? toPerson.name._;
        return <p key={to}>
          ${owe[to].toFixed(2)} to {name}<br />
        </p>
      })
    }
    </>
  );
}