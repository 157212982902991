import { ALLEACH } from "./constants";

export function calculateWhoOwesWhoWhat(totals, PEOPLE) {
  let owe = {}, owedBy = {}; // {'fromId1': {'toId2': amount12, 'toId3': amount13}}
    PEOPLE.map(person => person.id).forEach(id => { owe[id] = {}; owedBy[id] = {} });
    Object.keys(totals).forEach(from => {
      Object.keys(totals[from]).forEach(to => {
        let amount = totals[from][to];
        if (amount > 0) {
          owe[from][to] = amount;
          owedBy[to][from] = amount;
        } else if (amount < 0) {
          owe[to][from] = amount * -1;
          owedBy[from][to] = amount * -1;
        }
      })
    });
  return {owe: owe, owedBy: owedBy};
}

export function calculateUpdatedTotals_PaidFor(totals, {
  amount, from, tos, allEach
}) {
  let update = {};
  let eachAmount = (allEach === ALLEACH.each) ? amount : (amount / tos.length);
  tos.forEach(to => {
    if (from < to) {
      totals[from] = totals[from] ?? {};
      totals[from][to] ? totals[from][to] -= eachAmount : totals[from][to] = eachAmount * -1;
      let newVal = roundToTwo(totals[from][to]);
      update[from] = {...update[from], [to]: newVal};
      if (newVal == 0) delete totals[from][to];
      else totals[from][to] = newVal;
    } else if (from > to) {
      totals[to] = totals[to] ?? {};
      totals[to][from] ? totals[to][from] += eachAmount : totals[to][from] = eachAmount;
      let newVal = roundToTwo(totals[to][from]);
      update[to] = {...update[to], [from]: newVal};
      if (newVal == 0) delete totals[to][from];
      else totals[to][from] = newVal;
    }
  });
  return { update: update, newTotals: totals };
}

export function calculateUpdatedTotals_PaidBack(totals, {
  amount, from, to
}) {
  let update = {};
  if (from < to) {
    let newVal = roundToTwo(totals[from][to] - amount);
    if (newVal == 0) { 
      delete totals[from][to];
      update[from] = {[to]: 0}; 
    } else {
      totals[from][to] = newVal;
      update[from] = {[to]: newVal};
    }
  } else if (from > to) {
    let newVal = roundToTwo(totals[to][from] + amount);
    if (newVal == 0) {
      delete totals[to][from];
      update[to] = {[from]: 0};
    } else {
      totals[to][from] = newVal;
      update[to] = {[from]: newVal};
    }
  }
  return { update: update, newTotals: totals };
}

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}