import { useState } from "react";

import { TextField, Button } from "@mui/material"

import { signIn } from "../../firebase/auth"

export default function PasscodePage({ setAuthUser }) {
  const [passcode, setPasscode] = useState("");
  const [signInFailed, setSignInFailed] = useState(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    signIn(passcode)
    .then(authUser => setAuthUser(authUser))
    .catch(() => setSignInFailed(true));
  }

  return (<>
    <br /><br />
    <h1 className="nicefont">GimmeMoney!</h1>
    <form onSubmit={onFormSubmit}>
      <TextField 
        label="Passcode" 
        variant="outlined"
        type="password"
        value={passcode}
        onChange={e => setPasscode(e.target.value)}
      />
      <br /><br />
      <Button
        color="success"
        variant="contained"
        type="submit"
      >GO</Button>
      {signInFailed ? <p style={{color:"red"}}>Sign in failed.</p> : <></>}
    </form>
  </>)
}