import { useEffect, useState, useMemo, useContext } from "react";

import { getHistory } from "../../firebase/firestore";
import Loader from "../Loader";
import { Table } from "./table";
import { PersonContext } from "../../pages/dashboard";
import { DataContext } from "../../pages/dashboard";
import { 
  getPrettyTimestamp, 
  resolvePersonName, 
  resolveNameOrNames, 
  friendlyAmount
} from "../../utils";

export function HistorySection() {
  const { historyDocName } = useContext(DataContext);
  const { povPersonId, PEOPLE } = useContext(PersonContext);
  const [history, setHistory] = useState(null);
  const [err, setErr] = useState(false);

  const data = useMemo(() => {
    if (history == null) return [];
    return history.filter(item => (
      resolvePersonName(item.from, PEOPLE, povPersonId, "from") === "I" ||
      resolveNameOrNames(item.to, PEOPLE, povPersonId, "to").includes("Me")
    )).map(item => ({
      timestamp: getPrettyTimestamp(item.timestamp),
      from: resolvePersonName(item.from, PEOPLE, povPersonId, "from"),
      to: resolveNameOrNames(item.to, PEOPLE, povPersonId, "to"),
      amount: friendlyAmount(item.amount, item.alleach),
      type: item.type.replace("_", " ").toLowerCase(),
      description: item.description
    }));
  }, [history, povPersonId]);

  useEffect(() => {
    getHistory(historyDocName)
    .then(h => setHistory(h))
    .catch(e => {
      console.log(e);
      setErr(true);
    });
  }, []);

  return <>
    {history == null ? (err ? <p style={{color:'red'}}>
      Error loading history. See logs.
    </p> : <Loader visible/>) : <>
      <br />
      <Table data={data} />
    </>}
  </>
}