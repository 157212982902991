import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyBOO1sao2Dnkm4uIhaaC9-zBsVqBWYZigI",
  authDomain: "areeba-ca.firebaseapp.com",
  projectId: "areeba-ca",
  storageBucket: "areeba-ca.appspot.com",
  messagingSenderId: "808250092982",
  appId: "1:808250092982:web:f18c9f5498bb142ac66517",
  measurementId: "G-8RYSVY78D2"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;