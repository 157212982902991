import { Timestamp } from "@firebase/firestore"
import moment from "moment-timezone";

export const getPrettyTimestamp = (rawTimestamp) => {
  let jsDate = new Timestamp(rawTimestamp.seconds, rawTimestamp.nanoseconds).toDate();
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let tzAbbr = moment(jsDate).tz(timezone).format('z');
  return moment(jsDate).format("MMM D, Y, LT") + ` ${tzAbbr}`;
}

export const resolvePersonName = (personId, PEOPLE, povPersonId, context) => {
  let person = PEOPLE.filter(p => p.id == personId);
  if (person.length == 0) return ""; // couldn't find person with this id
  person = person[0];
  if (povPersonId == null) return person.name._;
  else if (povPersonId == personId) return context == "from" ? "I" : (context == "to" ? "Me" : person.name._);
  else return person.name[povPersonId] ?? person.name._;
}

export const resolveNameOrNames = (idOrIds, PEOPLE, povPersonId, context) => {
  if (typeof idOrIds == "string") {
    return resolvePersonName(idOrIds, PEOPLE, povPersonId, context);
  } else {
    return idOrIds.map(id => resolvePersonName(id, PEOPLE, povPersonId, context)).join(", ");
  }
}

export const friendlyAmount = (amount, alleach) => {
  let a = "$" + amount.toFixed(2);
  let ae = alleach ? (alleach == "ALL" ? "altogether" : alleach.toLowerCase()) : "";
  return a + " " + ae;
}