export const PEOPLE = {
  taskfam: [
    {
      id: "0",
      name: {
        _: 'Baba',
        "0": 'Aziz',
        "1": 'Aziz'
      }
    },
    {
      id: "1",
      name: {
        _: 'Mamma',
        "1": 'Afsheen',
        "0": 'Afsheen'
      }
    },
    {
      id: "2",
      name: {
        _: 'Talha',
        "3": 'Bhai',
        "4": 'Bhai',
        "5": 'Bhai'
      }
    },
    {
      id: "3",
      name: {
        _: 'Khadija',
        "4": 'Baji',
        "5": 'Baji'
      }
    },
    {
      id: "4",
      name: {
        _: 'Areeba',
        "5": 'Aapi'
      }
    },
    {
      id: "5",
      name: {
        _: 'Sheeza'
      }
    }
  ],
  playground: [
    {
      id: "0",
      name: {
        _: 'Bob',
        "1": 'Bobby',
        "2": 'Bobby',
        "3": 'Bobbina',
        "5": 'Bobbit'
      }
    },
    {
      id: "1",
      name: {
        _: 'Alice',
        "8": 'Ali',
        "9": 'Ali',
        "4": 'Ali',
        "3": 'Ali'
      }
    },
    {
      id: "2",
      name: {_: 'Ross'}
    },
    {
      id: "3",
      name: {_: 'Chandler'}
    },
    {
      id: "4",
      name: {
        _: 'Joey',
        "3": 'Jo'
      }
    },
    {
      id: "5",
      name: {
        _: "Monica",
        "2": "Mon",
        "3": "Mon",
        "4": "Mon",
        "6": "Mon",
        "7": "Mon"
      }
    },
    {
      id: "6",
      name: {
        _: "Rachel",
        "2": 'Rach',
        "3": 'Rach',
        "4": 'Rach',
        "5": 'Rach',
        "7": 'Rach'
      }
    },
    {
      id: "7",
      name: {
        _: "Phoebe",
        "2": 'Pheebs',
        "3": 'Pheebs',
        "4": 'Pheebs',
        "5": 'Pheebs',
        "6": 'Pheebs'
      }
    }
  ]
};

export const AUTH_USERS = [
  { 
    name: 'taskfam', 
    email: 'taskfam@fakeemail.com', 
    docNames: {
      totals: 'totals',
      history: 'history'
    }
  },
  { 
    name: 'playground', 
    email: 'playground@fakeemail.com', 
    docNames: {
      totals: 'totalsPlayground',
      history: 'historyPlayground'
    }
  }
]

export const ALLEACH = {
  all: 0,
  each: 1
};