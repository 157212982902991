setTimeout(() => {
  var canvas = document.getElementById('gimmick')
  if (canvas) {
    var fadeTarget = canvas; 
    var fadeEffect = setInterval(function () {
      if (!fadeTarget.style.opacity) {
        fadeTarget.style.opacity = 1;
      }
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.02;
      } else {
        clearInterval(fadeEffect);
      }
    }, 20);

    setTimeout(() => {
      canvas.parentNode.removeChild(canvas);
    }, 20*51);
  }
}, 1600);

var element = document.querySelector("body");
var canvas = document.createElement('canvas'),
  ctx = canvas.getContext('2d'),
  focused = false;

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;
canvas.id = 'gimmick'

var coin = new Image();
coin.src = 'http://i.imgur.com/5ZW2MT3.png'
// 440 wide, 40 high, 10 states
coin.onload = function () {
  element.prepend(canvas)
  focused = true;
  drawloop();
}
var coins = []

function drawloop() {
  if (focused) {
    requestAnimationFrame(drawloop);
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.fillStyle = "#c481f0";
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  if (Math.random() < .3) {
    coins.push({
      x: Math.random() * canvas.width | 0,
      y: -50,
      dy: 3,
      s: 0.5 + Math.random(),
      state: Math.random() * 10 | 0
    })
  }
  var i = coins.length
  while (i--) {
    var x = coins[i].x
    var y = coins[i].y
    var s = coins[i].s
    var state = coins[i].state
    coins[i].state = (state > 9) ? 0 : state + 0.1
    coins[i].dy += 0.3
    coins[i].y += coins[i].dy

    ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s)

    if (y > canvas.height) {
      coins.splice(i, 1);
    }
  }
}