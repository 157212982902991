import { 
  doc, 
  getDoc, 
  updateDoc, 
  deleteField, 
  collection, 
  query, 
  orderBy, 
  limit, 
  getDocs,
  setDoc,
  Timestamp
} from "firebase/firestore";
import db from "./firebase.config";
import moment from "moment-timezone";

const COLLECTION_NAME = "gimmemoney";

export const getTotals = async (totalsDocName) => {
  const docRef = doc(db, COLLECTION_NAME, totalsDocName);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
    throw new Error("Document doesn't exist");
  }
}

export const updateTotals = (totalsDocName, update) => {
  return new Promise(async function(resolve, reject) {
    const docRef = doc(db, COLLECTION_NAME, totalsDocName);
  
    for (const from of Object.keys(update)) {
      for (const to of Object.keys(update[from])) {
        let amount = update[from][to];
        if (amount == 0) {
          // Delete values of 0
          updateDoc(docRef, {
            [`${from}.${to}`]: deleteField()
          })
          .catch((e) => { console.log(e); throw e; });
        } else {
          // Update other values
          updateDoc(docRef, {
            [`${from}.${to}`]: amount
          })
          .catch((e) => { console.log(e); throw e; });
        }
      }
    }
    resolve("Success");
  });
}

export const getHistory = async (historyDocName) => {
  const q = query(collection(db, COLLECTION_NAME + '/' + historyDocName + '/history'), orderBy("timestamp", "desc"), limit(500));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data());
}

export const appendToHistory = async (historyDocName, data) => {
  return new Promise(async function(resolve, reject) {
    let date = new Date();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let id = moment(date).tz(timezone).format("Y-MM-DD_HH:mm:ss.SSS_z");
    setDoc(doc(db, COLLECTION_NAME, historyDocName, "history", id), {
      ...data,
      timestamp: Timestamp.fromDate(date)
    });
    resolve("Success");
  });
}