import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { CardContent, Card, Grid, Button } from '@mui/material';

import { DataContext, PersonContext } from '../../../pages/dashboard';
import { calculateUpdatedTotals_PaidBack } from '../../../calculate';
import { updateTotals, appendToHistory } from '../../../firebase/firestore';
import RecipientSelect from './RecipientSelect';
import Loader from '../../Loader';

export default function PaidForm({ owe }) {
  const { data: { totals }, setData, totalsDocName, historyDocName } = useContext(DataContext);
  const { povPersonId } = useContext(PersonContext);
  const [amount, setAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState(null);
  const [userEnteredAmount, setUserEnteredAmount] = useState(false);
  const [recipientId, setRecipientId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsgVisible, setErrorMsgVisible] = useState(false);

  useEffect(() => {
    // on change of pov person (therefore owe), reset values
    setMaxAmount(null);
    setAmount("");
    setRecipientId("");
    setUserEnteredAmount(false);
  }, [owe])

  const onRecipientChange = recipId => {
    setRecipientId(recipId);
    setAmount(owe[recipId] ? (userEnteredAmount && (amount <= owe[recipId]) ? amount : owe[recipId]) : "");
    setMaxAmount(owe[recipId] ?? null);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setErrorMsgVisible(false);
    let { newTotals, update } = calculateUpdatedTotals_PaidBack(totals, {
      amount: Number(amount),
      from: povPersonId,
      to: recipientId
    });
    setData(prev => ({...prev, totals: newTotals}));
    setLoading(true);
    updateTotals(totalsDocName, update)
      .then(msg => setLoading(false))
      .catch(() => setErrorMsgVisible(true));
    
    appendToHistory(historyDocName, {
      from: povPersonId,
      to: recipientId,
      amount: Number(amount),
      type: "PAID_BACK"
    });
  }

  return (
    <>
      <br/>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '10vh' }}
      >
        <Grid item xs={3}>
          <Card sx={{ minWidth: 350, maxWidth: 350 }}>
            <CardContent>
              {Object.keys(owe).length === 0 ? <p>
                You don't owe anyone anything! :)
                </p> : <>
                  <form onSubmit={onFormSubmit}>
                    <p>I paid $ <input 
                      type="number" 
                      id="paidAmount" 
                      name="paidAmount" 
                      min="0.01"
                      max={maxAmount}
                      step="0.01"
                      size="7" 
                      value={amount}
                      onChange={e => {
                        setAmount(e.target.value);
                        setUserEnteredAmount(true);
                      }}
                      required
                      />
                    &nbsp; to <RecipientSelect 
                      oweIds={Object.keys(owe)}
                      onRecipientChange={onRecipientChange}
                      recipientId={recipientId}
                      />.</p>
                    <Button 
                      color="success" 
                      variant="contained"
                      size="small"
                      type="submit"
                    >Submit</Button>
                  </form>
                  <Loader visible={loading}/>
                  {errorMsgVisible ? <p style={{color: 'red'}}>
                    Failed to update data. Please see console logs. 
                  </p> : <></>}
                  </>
                }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}