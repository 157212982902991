import { Form } from "react-bootstrap";
import { useContext } from "react";

import { PersonContext } from "../../pages/dashboard";

export default function PersonSelect() {
  const { setPovPersonId, PEOPLE } = useContext(PersonContext);

  function handleChange(event) {
    setPovPersonId(event.target.value);
  }

  return (
    <Form.Select aria-label="Person select" onChange={handleChange}>
      <option>Select</option>
      {
        PEOPLE.map(person => (
          <option key={person.id} value={person.id}>{person.name._}</option>
        ))
      }
    </Form.Select>
  );
}