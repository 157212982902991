import * as React from 'react';
import { useState, useContext } from 'react';
import { CardContent, Card, Grid, Button, Input } from '@mui/material';

import PersonMultiSelect from '../../PersonMultiSelect';
import AllEachSelect from './AllEachSelect';
import { ALLEACH } from '../../../constants';
import { DataContext, PersonContext } from '../../../pages/dashboard';
import { updateTotals, appendToHistory } from '../../../firebase/firestore';
import { calculateUpdatedTotals_PaidFor } from '../../../calculate';
import Loader from '../../Loader';

export default function PaidForForm() {
  const { data: { totals }, setData, totalsDocName, historyDocName } = useContext(DataContext);
  const { povPersonId } = useContext(PersonContext);
  const [amount, setAmount] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [allEach, setAllEach] = useState(ALLEACH.all);
  const [loading, setLoading] = useState(false);
  const [errorMsgVisible, setErrorMsgVisible] = useState(false);
  const [description, setDescription] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    let { newTotals, update } = calculateUpdatedTotals_PaidFor(totals, {
      amount: Number(amount),
      from: povPersonId,
      tos: selectedIds,
      allEach: Number(allEach)
    });
    setData(prev => ({ ...prev, totals: newTotals }));
    updateTotals(totalsDocName, update)
      .then(msg => setLoading(false))
      .catch(() => setErrorMsgVisible(true));

    let historyEvent = {
      from: povPersonId,
      to: selectedIds,
      amount: Number(amount),
      type: "PAID_FOR",
      description: description
    }
    if (selectedIds.length > 1)
      historyEvent["alleach"] = allEach == ALLEACH.all ? "ALL" : "EACH"
    appendToHistory(historyDocName, historyEvent);
    
    setAmount("");
    setSelectedIds([]);
    setAllEach(ALLEACH.all);
    setDescription("");
  }

  return (
    <>
      <br />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '10vh' }}
      >
        <Grid item xs={3}>
          <Card sx={{ minWidth: 350, maxWidth: 350 }}>
            <CardContent>
              <form onSubmit={onFormSubmit}>
                I paid $ <input
                  type="number"
                  id="paidAmount"
                  name="paidAmount"
                  min="0.01"
                  step="0.01"
                  size="7" 
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  required
                  /> for <br /><br />
                  <Input 
                    placeholder="eg. dinner"
                    value={description}
                    inputProps={{ maxLength: 50 }}
                    onChange={e => setDescription(e.target.value)}
                  /> for 
                  <br /><br />
                  <PersonMultiSelect 
                    selectedIds={selectedIds} 
                    setSelectedIds={setSelectedIds}
                  /> 
                  <br /><br />
                  <AllEachSelect allEach={allEach} setAllEach={setAllEach} />.<br /><br />
                <Button
                  color="success"
                  variant="contained"
                  size="small"
                  type="submit"
                >Submit</Button>
              </form>
              <Loader visible={loading} />
              {errorMsgVisible ? <p style={{ color: 'red' }}>
                Failed to update data. Please see console logs.
              </p> : <></>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}