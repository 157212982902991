import Container from '@mui/material/Container';
import { createContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import PersonSelect from '../../components/PersonSelect';
import YouOweList from '../../components/YouOweList';
import WhoOwesYouList from '../../components/WhoOwesYouList';
import RecordPaymentSection from '../../components/RecordPaymentSection';
import { calculateWhoOwesWhoWhat } from '../../calculate'; 
import { getTotals } from '../../firebase/firestore';
import { PEOPLE } from '../../constants';
import './fallingMoney';
import { HistorySection } from '../../components/HistorySection';

export const PersonContext = createContext(null);
export const DataContext = createContext(null);

export default function DashboardPage({ authUser }) {
  const [err, setErr] = useState(false);
  const [povPersonId, setPovPersonId] = useState(null);
  const [data, setData] = useState({totals: {}});
  const [showHistory, setShowHistory] = useState(false);

  const personContextValue = { 
    povPersonId, 
    setPovPersonId, 
    PEOPLE: PEOPLE[authUser.name] 
  };
  const { owe, owedBy } = calculateWhoOwesWhoWhat(data.totals, personContextValue.PEOPLE);

  useEffect(() => {
    getTotals(authUser.docNames.totals)
    .then(value => setData(prev => ({...prev, totals: value})))
    .catch(err => {
        console.log(err);
        setErr(true);
    });
  }, []);

  return (
    <DataContext.Provider value={{ 
      data: data, setData: setData, 
      totalsDocName: authUser.docNames.totals,
      historyDocName: authUser.docNames.history
      }}>
      <PersonContext.Provider value={personContextValue}>
        <Container>
          <h1 className="nicefont">GimmeMoney!</h1>
          <div id="whoareyou">
            <span>Who are you? </span>
            <PersonSelect />
          </div>
          {isNaN(povPersonId) || povPersonId === null ? <></> :
            (err ? <p style={{color:"red"}}>
              There was an error fetching data. Check logs.
              </p> :
              (<>
                <RecordPaymentSection owe={owe[povPersonId] ?? {}} />
                <h2 className="nicefont">You owe...</h2>
                <YouOweList owe={owe[povPersonId] ?? {}}/>
                <h2 className="nicefont">Who owes you...</h2>
                <WhoOwesYouList owedBy={owedBy[povPersonId] ?? {}}/>
              </>)
            )
          }
          <br />
          <Button
            variant="contained"
            color="warning"
            onClick={() => setShowHistory(prev => !prev)}
          >History</Button>
          {showHistory ? <HistorySection /> : <></>}
        </Container>
      </PersonContext.Provider>
    </DataContext.Provider>
  );
}
