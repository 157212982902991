import { useState } from "react";

import './App.css';
import DashboardPage from '../pages/dashboard';
import PasscodePage from "../pages/passcode";

export default function App() {
  const [authUser, setAuthUser] = useState(null);
  return (
    <div className="App">
      {authUser ? <DashboardPage authUser={authUser} /> : <PasscodePage setAuthUser={setAuthUser} />}
    </div>
  );
}