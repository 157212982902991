import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import PaidForm from './PaidForm';
import PaidForForm from './PaidForForm';
import './index.css';

export default function RecordPaymentSection({ owe }) {
  const [display, setDisplay] = useState(0); // 0 - none, 1 - paid debt, 2 - paid for

  const paidForBtnClicked = () => {
    switch (display) {
      case 0:
      case 2:
        setDisplay(1);
        break;
      case 1:
        setDisplay(0);
        break;
    }
  }

  const paidForPeopleBtnClicked = () => {
    switch (display) {
      case 0:
      case 1:
        setDisplay(2);
        break;
      case 2:
        setDisplay(0);
        break;
    }
  }

  return (
    <><br/>
      <Stack spacing={2} direction="row"
        justifyContent="center"
        alignItems="center">
        <Button variant="contained" onClick={paidForBtnClicked}>I paid back</Button>
        <Button variant="contained" onClick={paidForPeopleBtnClicked}>I paid for</Button>
      </Stack>
      {display === 1 ? <PaidForm owe={owe} /> : display === 2 ? <PaidForForm /> : <></>}
    </>
  );
}